import { default as firstrunD5eZnGDmfMMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/firstrun.vue?macro=true";
import { default as forgot_45password7e4RaIhWQsMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/forgot-password.vue?macro=true";
import { default as indexpj0RKG64MAMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/index.vue?macro=true";
import { default as loginr8OLbdiS4gMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/login.vue?macro=true";
import { default as meet_45the_45teampLYaDRm9rGMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/meet-the-team.vue?macro=true";
import { default as _91_91filter_93_93DxTcCnsNM8Meta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue?macro=true";
import { default as addDi4haxToREMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/movers/add.vue?macro=true";
import { default as _91id_93GDuLcNY0X2Meta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/movers/edit/[id].vue?macro=true";
import { default as indexWMz6EA4KiKMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/movers/index.vue?macro=true";
import { default as _91_91step_93_937A8oTvo1vBMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue?macro=true";
import { default as _91_91step_93_93n1Vxi4bCCoMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue?macro=true";
import { default as indexKLYYTXJZ6vMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/index.vue?macro=true";
import { default as pendingCM0JEAMNVqMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/pending.vue?macro=true";
import { default as _91id_93WKb5fhKFKfMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/view/[id].vue?macro=true";
import { default as _91_91step_93_93ZE0fflR86eMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue?macro=true";
import { default as indexQhdyhSUr8sMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/void/index.vue?macro=true";
import { default as addATP6NqHELmMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/partner-users/add.vue?macro=true";
import { default as _91id_93pt2kRomZYnMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue?macro=true";
import { default as indexMws3DzstkZMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/partner-users/index.vue?macro=true";
import { default as _91token_93pSdXublEVoMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/password/reset/[token].vue?macro=true";
import { default as add9FBlJsHGWbMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/properties/add.vue?macro=true";
import { default as _91id_93sUb3DNo25DMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/properties/edit/[id].vue?macro=true";
import { default as indexNlm8gDHNAyMeta } from "/home/forge/goose.app.jmi.cloud/apps/partner/pages/properties/index.vue?macro=true";
export default [
  {
    name: firstrunD5eZnGDmfMMeta?.name ?? "firstrun",
    path: firstrunD5eZnGDmfMMeta?.path ?? "/firstrun",
    meta: firstrunD5eZnGDmfMMeta || {},
    alias: firstrunD5eZnGDmfMMeta?.alias || [],
    redirect: firstrunD5eZnGDmfMMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/firstrun.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password7e4RaIhWQsMeta?.name ?? "forgot-password",
    path: forgot_45password7e4RaIhWQsMeta?.path ?? "/forgot-password",
    meta: forgot_45password7e4RaIhWQsMeta || {},
    alias: forgot_45password7e4RaIhWQsMeta?.alias || [],
    redirect: forgot_45password7e4RaIhWQsMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexpj0RKG64MAMeta?.name ?? "index",
    path: indexpj0RKG64MAMeta?.path ?? "/",
    meta: indexpj0RKG64MAMeta || {},
    alias: indexpj0RKG64MAMeta?.alias || [],
    redirect: indexpj0RKG64MAMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginr8OLbdiS4gMeta?.name ?? "login",
    path: loginr8OLbdiS4gMeta?.path ?? "/login",
    meta: loginr8OLbdiS4gMeta || {},
    alias: loginr8OLbdiS4gMeta?.alias || [],
    redirect: loginr8OLbdiS4gMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/login.vue").then(m => m.default || m)
  },
  {
    name: meet_45the_45teampLYaDRm9rGMeta?.name ?? "meet-the-team",
    path: meet_45the_45teampLYaDRm9rGMeta?.path ?? "/meet-the-team",
    meta: meet_45the_45teampLYaDRm9rGMeta || {},
    alias: meet_45the_45teampLYaDRm9rGMeta?.alias || [],
    redirect: meet_45the_45teampLYaDRm9rGMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/meet-the-team.vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_93DxTcCnsNM8Meta?.name ?? "move-notifications-index-filter",
    path: _91_91filter_93_93DxTcCnsNM8Meta?.path ?? "/move-notifications/:filter?",
    meta: _91_91filter_93_93DxTcCnsNM8Meta || {},
    alias: _91_91filter_93_93DxTcCnsNM8Meta?.alias || [],
    redirect: _91_91filter_93_93DxTcCnsNM8Meta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/move-notifications/index/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: addDi4haxToREMeta?.name ?? "movers-add",
    path: addDi4haxToREMeta?.path ?? "/movers/add",
    meta: addDi4haxToREMeta || {},
    alias: addDi4haxToREMeta?.alias || [],
    redirect: addDi4haxToREMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/movers/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GDuLcNY0X2Meta?.name ?? "movers-edit-id",
    path: _91id_93GDuLcNY0X2Meta?.path ?? "/movers/edit/:id()",
    meta: _91id_93GDuLcNY0X2Meta || {},
    alias: _91id_93GDuLcNY0X2Meta?.alias || [],
    redirect: _91id_93GDuLcNY0X2Meta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/movers/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWMz6EA4KiKMeta?.name ?? "movers",
    path: indexWMz6EA4KiKMeta?.path ?? "/movers",
    meta: indexWMz6EA4KiKMeta || {},
    alias: indexWMz6EA4KiKMeta?.alias || [],
    redirect: indexWMz6EA4KiKMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/movers/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91step_93_937A8oTvo1vBMeta?.name ?? "moves-add-step",
    path: _91_91step_93_937A8oTvo1vBMeta?.path ?? "/moves/add/:step?",
    meta: _91_91step_93_937A8oTvo1vBMeta || {},
    alias: _91_91step_93_937A8oTvo1vBMeta?.alias || [],
    redirect: _91_91step_93_937A8oTvo1vBMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/add/[[step]].vue").then(m => m.default || m)
  },
  {
    name: _91_91step_93_93n1Vxi4bCCoMeta?.name ?? "moves-edit-id-step",
    path: _91_91step_93_93n1Vxi4bCCoMeta?.path ?? "/moves/edit/:id()/:step?",
    meta: _91_91step_93_93n1Vxi4bCCoMeta || {},
    alias: _91_91step_93_93n1Vxi4bCCoMeta?.alias || [],
    redirect: _91_91step_93_93n1Vxi4bCCoMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/edit/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: indexKLYYTXJZ6vMeta?.name ?? "moves",
    path: indexKLYYTXJZ6vMeta?.path ?? "/moves",
    meta: indexKLYYTXJZ6vMeta || {},
    alias: indexKLYYTXJZ6vMeta?.alias || [],
    redirect: indexKLYYTXJZ6vMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/index.vue").then(m => m.default || m)
  },
  {
    name: pendingCM0JEAMNVqMeta?.name ?? "moves-pending",
    path: pendingCM0JEAMNVqMeta?.path ?? "/moves/pending",
    meta: pendingCM0JEAMNVqMeta || {},
    alias: pendingCM0JEAMNVqMeta?.alias || [],
    redirect: pendingCM0JEAMNVqMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/pending.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WKb5fhKFKfMeta?.name ?? "moves-view-id",
    path: _91id_93WKb5fhKFKfMeta?.path ?? "/moves/view/:id()",
    meta: _91id_93WKb5fhKFKfMeta || {},
    alias: _91id_93WKb5fhKFKfMeta?.alias || [],
    redirect: _91id_93WKb5fhKFKfMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/view/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_91step_93_93ZE0fflR86eMeta?.name ?? "moves-void-id-step",
    path: _91_91step_93_93ZE0fflR86eMeta?.path ?? "/moves/void/:id()/:step?",
    meta: _91_91step_93_93ZE0fflR86eMeta || {},
    alias: _91_91step_93_93ZE0fflR86eMeta?.alias || [],
    redirect: _91_91step_93_93ZE0fflR86eMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/void/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: indexQhdyhSUr8sMeta?.name ?? "moves-void",
    path: indexQhdyhSUr8sMeta?.path ?? "/moves/void",
    meta: indexQhdyhSUr8sMeta || {},
    alias: indexQhdyhSUr8sMeta?.alias || [],
    redirect: indexQhdyhSUr8sMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/moves/void/index.vue").then(m => m.default || m)
  },
  {
    name: addATP6NqHELmMeta?.name ?? "partner-users-add",
    path: addATP6NqHELmMeta?.path ?? "/partner-users/add",
    meta: addATP6NqHELmMeta || {},
    alias: addATP6NqHELmMeta?.alias || [],
    redirect: addATP6NqHELmMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/partner-users/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pt2kRomZYnMeta?.name ?? "partner-users-edit-id",
    path: _91id_93pt2kRomZYnMeta?.path ?? "/partner-users/edit/:id()",
    meta: _91id_93pt2kRomZYnMeta || {},
    alias: _91id_93pt2kRomZYnMeta?.alias || [],
    redirect: _91id_93pt2kRomZYnMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/partner-users/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMws3DzstkZMeta?.name ?? "partner-users",
    path: indexMws3DzstkZMeta?.path ?? "/partner-users",
    meta: indexMws3DzstkZMeta || {},
    alias: indexMws3DzstkZMeta?.alias || [],
    redirect: indexMws3DzstkZMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/partner-users/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93pSdXublEVoMeta?.name ?? "password-reset-token",
    path: _91token_93pSdXublEVoMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93pSdXublEVoMeta || {},
    alias: _91token_93pSdXublEVoMeta?.alias || [],
    redirect: _91token_93pSdXublEVoMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: add9FBlJsHGWbMeta?.name ?? "properties-add",
    path: add9FBlJsHGWbMeta?.path ?? "/properties/add",
    meta: add9FBlJsHGWbMeta || {},
    alias: add9FBlJsHGWbMeta?.alias || [],
    redirect: add9FBlJsHGWbMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/properties/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sUb3DNo25DMeta?.name ?? "properties-edit-id",
    path: _91id_93sUb3DNo25DMeta?.path ?? "/properties/edit/:id()",
    meta: _91id_93sUb3DNo25DMeta || {},
    alias: _91id_93sUb3DNo25DMeta?.alias || [],
    redirect: _91id_93sUb3DNo25DMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/properties/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexNlm8gDHNAyMeta?.name ?? "properties",
    path: indexNlm8gDHNAyMeta?.path ?? "/properties",
    meta: indexNlm8gDHNAyMeta || {},
    alias: indexNlm8gDHNAyMeta?.alias || [],
    redirect: indexNlm8gDHNAyMeta?.redirect,
    component: () => import("/home/forge/goose.app.jmi.cloud/apps/partner/pages/properties/index.vue").then(m => m.default || m)
  }
]